import { Bars3Icon } from "@heroicons/react/24/outline";
import { IQuestion, QuestionCategories, questionMetadata } from "@sp-crm/core";
import { Header } from "components/header";
import { Loading } from "components/loading";
import { Pill } from "components/ui/pill";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import { useReorderLegacyQuestionsMutation } from "generated/graphql";
import * as React from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action, Dispatch } from "redux";
import { createQuestion, loadSettings } from "store/actions";
import { useRegions } from "store/selectors/hooks";
import { ApplicationState } from "store/state";
import { useReorderableListDrag, useReorderableListDrop } from "util/dnd";
import { categoryLabel } from "./label-helper";

interface CategoryListProps {
    category: QuestionCategories;
    questions: IQuestion[];
    dispatch: Dispatch<Action>;
}

const linkText = (q: IQuestion): string => {
    if (!q) {
        return "ERROR";
    }
    const clientTitle = q.legacyClientTitle?.trim();
    const communityTitle = q.legacyCommunityTitle?.trim();

    if (!clientTitle && !communityTitle) {
        return `Untitled (${q.id})`;
    }
    if (communityTitle && !clientTitle) {
        return q.legacyCommunityTitle;
    }
    if (clientTitle && !communityTitle) {
        return q.legacyClientTitle;
    }
    if (clientTitle === communityTitle) {
        return q.legacyClientTitle;
    }
    return `${clientTitle} / ${communityTitle}`;
};

const CategoryList: React.FunctionComponent<CategoryListProps> = props => {
    const [isReordering, setIsReordering] = React.useState(false);
    const finishReordering = useCallback(() => setIsReordering(false), []);
    const regions = useRegions();
    const details = questionMetadata[props.category];
    if (!details) {
        return <h1>Cannot load details for category {props.category}</h1>;
    }
    const sortedQuestions = props.questions
        .slice()
        .sort((a, b) => (a.order || 0) - (b.order || 0));
    return (
        <div className="input-form-block max-50-wide">
            <Header iconName={details.icon}>{categoryLabel(details)}</Header>
            {isReordering ? (
                <ReorderableCategoryList {...props} finish={finishReordering} />
            ) : (
                <div className="space-y-4">
                    <div className="flex space-x-2 items-center">
                        <SecondaryButton onClick={() => setIsReordering(true)}>
                            <div className="text-sm">Reorder fields</div>
                        </SecondaryButton>
                    </div>
                    <ul className="space-y-1">
                        {sortedQuestions.map(q => {
                            let regionPillTexts =
                                q.regionMask?.map(
                                    m => regions.find(r => r.key === m)?.name || m,
                                ) || [];
                            if (regionPillTexts.length > 3) {
                                const firstTwo = regionPillTexts.slice(0, 2);
                                regionPillTexts = [
                                    ...firstTwo,
                                    `+${regionPillTexts.length - 2} more`,
                                ];
                            }

                            return (
                                <li key={q.id} className="px-6 py-2">
                                    <div className="flex space-x-2 items-center">
                                        <Link
                                            to={`/settings/agency/customize/cc/${q.id}`}>
                                            {linkText(q)}
                                        </Link>
                                        {regionPillTexts.length > 0 ? (
                                            <div className="flex-1 space-x-2">
                                                {regionPillTexts.map((text, i) => (
                                                    <Pill key={i}>{text}</Pill>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export const List: React.FunctionComponent = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const settings = useSelector((state: ApplicationState) => state.settings);
    const newQuestionOrder = (settings.legacyQuestions || []).reduce(
        (a, b) => Math.max(a, b.order || 0),
        -1,
    );
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const addQuestion = useCallback(
        () => createQuestion(newQuestionOrder + 1000, queryClient, dispatch),
        [newQuestionOrder, dispatch, queryClient],
    );

    const content = () => {
        const questions = settings.legacyQuestions || [];
        return (
            Object.keys(QuestionCategories)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                .filter(key => !isNaN(Number((QuestionCategories as any)[key])))
                .map(k => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                    const category = (QuestionCategories as any)[k];
                    const categorySpecificQuestions = questions.filter(
                        q => q.category === category,
                    );
                    if (categorySpecificQuestions.length === 0) return null;
                    return (
                        <CategoryList
                            key={k}
                            dispatch={dispatch}
                            category={category}
                            questions={categorySpecificQuestions}
                        />
                    );
                })
        );
    };
    return (
        <div className="space-y-4">
            <div className="flex space-x-4">
                <SecondaryButton onClick={addQuestion}>
                    Add custom setting
                </SecondaryButton>
            </div>
            <div>
                {settings.legacyQuestionsLoaded ? (
                    content()
                ) : (
                    <Loading componentName="Settings" />
                )}
            </div>
        </div>
    );
};

interface ReorderableCategoryListProps extends CategoryListProps {
    finish: () => void;
}

const ReorderableCategoryList: React.FunctionComponent<
    ReorderableCategoryListProps
> = props => {
    const { finish } = props;
    const [sortedQuestions, setSortedQuestions] = React.useState(
        props.questions.slice().sort((a, b) => (a.order || 0) - (b.order || 0)),
    );
    const updateOrder = useCallback(
        (dragIndex: number, hoverIndex: number) =>
            setSortedQuestions(questions => {
                const dragQuestion = questions[dragIndex];
                const newQuestions = [...questions];
                newQuestions.splice(dragIndex, 1);
                newQuestions.splice(hoverIndex, 0, dragQuestion);
                return newQuestions;
            }),
        [],
    );
    const noop = useCallback(() => {}, []);
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = React.useState(false);
    const reorderLegacyQuestions = useReorderLegacyQuestionsMutation();
    const save = useCallback(async () => {
        setIsSaving(true);
        await reorderLegacyQuestions.mutateAsync({
            questionReorderPayload: {
                questionIds: sortedQuestions.map(q => q.id),
            },
        });
        await loadSettings(dispatch);
        setIsSaving(false);
        finish();
    }, [sortedQuestions, reorderLegacyQuestions, finish, dispatch]);

    const details = questionMetadata[props.category];
    if (!details) {
        return <h1>Cannot load details for category {props.category}</h1>;
    }

    return (
        <div className="space-y-4">
            <div className="flex space-x-2 items-center">
                <PrimaryButton onClick={save} disabled={isSaving}>
                    <div className="text-sm">{isSaving ? <Spinner /> : "Save"}</div>
                </PrimaryButton>
                <SecondaryButton disabled={isSaving} onClick={finish}>
                    <div className="text-sm">Cancel</div>
                </SecondaryButton>
            </div>

            <ul className="space-y-1">
                {sortedQuestions.map((q, i) => (
                    <ReorderableQuestion
                        key={q.id}
                        question={q}
                        index={i}
                        commitOrder={noop}
                        revertOrder={noop}
                        reorder={updateOrder}
                    />
                ))}
            </ul>
        </div>
    );
};

interface ReorderableQuestion {
    index: number;
    reorder: (draggingIndex: number, targetIndex: number) => void;
    commitOrder: () => void;
    revertOrder: () => void;
    question: IQuestion;
}

const ReorderableQuestion: React.FunctionComponent<ReorderableQuestion> = props => {
    const { question, index, reorder, commitOrder, revertOrder } = props;

    const ref = React.useRef<HTMLLIElement>(null);
    const type = "legacyQuestion";
    const [{ isDragging }, drag, preview] = useReorderableListDrag({
        type,
        index,
        commitOrder,
        revertOrder,
        id: question.id,
    });
    const [_, drop] = useReorderableListDrop({
        ref,
        index,
        reorder,
        type,
    });
    drop(ref);

    return (
        <li ref={ref}>
            <div
                ref={preview}
                className={`flex items-center ${isDragging ? "opacity-0" : ""}`}>
                <div className="cursor-move px-1 py-3" ref={drag}>
                    <Bars3Icon className="w-4 h-4 text-gray-500" />
                </div>
                <div className="flex items-center space-x-2">
                    <div>{linkText(question)}</div>
                </div>
            </div>
        </li>
    );
};
